import React from "react";
import { Button } from "../../../components/Button/Button";
import { Link } from "react-router-dom";

function toHoursAndMinutes(totalMinutes) {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    if(minutes){
        return `${hours} hour${hours === 1 ? "" : "s"} and ${minutes} minutes`;
    }
    else{
        return `${hours} Hours`;
    }
}


export const LandingPage = ({name, readAllCatalogQuestions, readCatalog, setStarted}) => {
    return (
        <div className="flex-column practiceIntro-container primary-background padding-top-100 overflow-hidden">
            <div className="horizontal-align flex-column">
                <h2 className="color-white font-size-4rem">
                    {name}
                </h2>
                <div className="practiceImage-container">
                    <img src={require(`../../../imgs/${name}.png`)} class="practiceIntro-image" alt={name}/>
                </div>
                <div className="flex-row practiceStat-container">
                    <h3 className="fit-content white-text practiceIntro-stat">{readAllCatalogQuestions?.length} questions</h3>
                    <h3 className="fit-content white-text practiceIntro-bar" style={{margin: "0 2vw"}}>|</h3>
                    <h3 className="fit-content white-text practiceIntro-stat">{toHoursAndMinutes(readCatalog.time)}</h3>
                    <h3 className="fit-content white-text practiceIntro-bar" style={{margin: "0 2vw"}}>|</h3>
                    <h3 className="fit-content white-text practiceIntro-stat">{readCatalog.passPercentage}% correct required to pass</h3>
                </div>
                <div className="practiceDescription-container">
                    <p className="description white-text text-align-center">
                        {readCatalog.description}
                    </p>
                </div>
            </div>
            <div className="greyBackground2-image practiceIntroButton-container">
                <div className="flex-column">
                    <div className="flex-row">
                        <div className="button-container">
                            {
                                localStorage.getItem("currentExam") ? (
                                    <Button text={"Continue Exam"} func={() => {setStarted(true)}}/>
                                ) : (
                                    <Button text={"Start exam"} func={() => {setStarted(true)}}/>
                                )
                            }
                        </div>
                        <div className="button-container">
                            <Link to={"/stats/" + name}>
                                <Button text={"View Stats"} func={() => {}}/>
                            </Link>
                            {/* <Button text={"View Stats 🔒"} func={() => {}}/> */}
                        </div>
                        <div className="button-container">
                            {/* <Link to={"/training"}>
                                <Button text={"Practice for Exam"} func={() => {}}/>
                            </Link> */}
                            <Button text={"Practice for Exam 🔒"} func={() => {}}/>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}